<template>
  <div class="d-flex justify-center flex-wrap pb-4">
    <v-card
      v-for="item in items"
      :key="`card${item.student.id}`"
      :color="color(item)"
      outlined
      :width="$vuetify.breakpoint.xs ? '44%' : `${zoom}px`"
      class="ma-2 pa-1"
      :class="{
        full: full(item),
        disabled: disabled(item),
        striped: striped(item),
      }"
      :ripple="!disabled(item)"
      @click="!disabled(item) ? toggle(item) : ''"
    >
      <v-overlay v-if="item.loading" absolute>
        <v-progress-circular indeterminate></v-progress-circular
      ></v-overlay>
      <div>
        <PortraitImage large :value="item.student" />
      </div>
      <v-card-text class="text-center ma-0 pb-2">
        <v-tooltip top v-if="item.absentElsewhere">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" :attrs="attrs" left>mdi-school</v-icon>
          </template>
          <span>In einem Parallelkurs als abwesend registriert.</span>
        </v-tooltip>
        <v-tooltip top v-if="listViewTooltip(item.periodItems)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              :attrs="attrs"
              :to="{
                name: 'AttendanceCheckList',
              }"
              icon
            >
              <v-icon>mdi-list-status</v-icon>
            </v-btn>
          </template>
          <span
            >{{
              item.periodItems
                .map(
                  (el, index) =>
                    index +
                    1 +
                    ". Lektion: " +
                    (el.present ? "anwesend" : "abwesend")
                )
                .join(", ")
            }}<br />
            Details in Listenansicht</span
          >
        </v-tooltip>
        <v-tooltip top v-if="item.presentElsewhere">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" :attrs="attrs" left>mdi-calendar</v-icon>
          </template>
          <span>Wegen eines Schulanlasses abwesend.</span>
        </v-tooltip>
        <v-tooltip
          top
          v-for="justification in item.justifications"
          :key="'justification' + justification.id"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" :attrs="attrs" left>{{
              justificationCategoryIcon(justification.category)
            }}</v-icon> </template
          >Absenz: {{ justification.category.description }}
        </v-tooltip>
        <v-tooltip
          top
          v-for="dispensation in item.dispensations"
          :key="'dispensation' + dispensation.id"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" :attrs="attrs" left>mdi-cancel</v-icon>
          </template>
          <span
            >{{
              dispensation.physicalEducation
                ? "Sportdispensation"
                : "Dispensation"
            }}<span v-if="dispensation.description">: </span>
            {{ dispensation.description }}</span
          >
        </v-tooltip>
        <PersonName :value="item.student" flags />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";

import { justificationCategoryIcon } from "common/utils/icons.js";

export default defineComponent({
  components: { PersonName, PortraitImage },
  props: ["items", "zoom"],
  data() {
    return {};
  },
  methods: {
    justificationCategoryIcon,
    absent(item) {
      return !item.present;
    },
    color(item) {
      if (item.dispensated || item.presentElsewhere) {
        return "elsewhere";
      }
      if (item.absentElsewhere) {
        return "full";
      }
      if (item.absentHere) {
        return "full";
      } else if (item.present) {
        return "";
      } else {
        return "striped";
      }
    },
    disabled(item) {
      return (
        !item.active ||
        item.loading ||
        item.dispensated ||
        item.absentElsewhere ||
        item.presentElsewhere ||
        (!item.absentHere && !item.present)
      );
    },
    full(item) {
      return this.color(item) == "full";
    },
    listViewTooltip(periodItems) {
      return !periodItems.every((v) => v.present === periodItems[0].present);
    },
    striped(item) {
      return this.color(item) == "striped";
    },
    async toggle(item) {
      this.$set(item, "loading", true);
      this.$emit(
        "toggle",
        item,
        item.periodItems,
        item.toggleStatus == "absent"
      );
    },
  },
});
</script>
<style scoped>
.disabled {
  opacity: 0.8;
  cursor: not-allowed !important;
}
.elsewhere {
  background-color: rgb(65 167 209 / 32%);
  border-color: #41a7d1;
}
.full {
  background-color: rgb(236, 172, 172);
  border-color: #ea4335;
}
.striped {
  border-color: #ea4335;
  background-image: repeating-linear-gradient(
    45deg,
    rgb(255, 255, 255),
    rgb(255, 255, 255) 10px,
    rgb(236, 172, 172) 10px,
    rgb(236, 172, 172) 20px
  );
}
</style>
