var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-center flex-wrap pb-4"},_vm._l((_vm.items),function(item){return _c('v-card',{key:`card${item.student.id}`,staticClass:"ma-2 pa-1",class:{
      full: _vm.full(item),
      disabled: _vm.disabled(item),
      striped: _vm.striped(item),
    },attrs:{"color":_vm.color(item),"outlined":"","width":_vm.$vuetify.breakpoint.xs ? '44%' : `${_vm.zoom}px`,"ripple":!_vm.disabled(item)},on:{"click":function($event){!_vm.disabled(item) ? _vm.toggle(item) : ''}}},[(item.loading)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),_c('div',[_c('PortraitImage',{attrs:{"large":"","value":item.student}})],1),_c('v-card-text',{staticClass:"text-center ma-0 pb-2"},[(item.absentElsewhere)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g({attrs:{"attrs":attrs,"left":""}},on),[_vm._v("mdi-school")])]}}],null,true)},[_c('span',[_vm._v("In einem Parallelkurs als abwesend registriert.")])]):_vm._e(),(_vm.listViewTooltip(item.periodItems))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g({attrs:{"attrs":attrs,"to":{
              name: 'AttendanceCheckList',
            },"icon":""}},on),[_c('v-icon',[_vm._v("mdi-list-status")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.periodItems .map( (el, index) => index + 1 + ". Lektion: " + (el.present ? "anwesend" : "abwesend") ) .join(", "))),_c('br'),_vm._v(" Details in Listenansicht")])]):_vm._e(),(item.presentElsewhere)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g({attrs:{"attrs":attrs,"left":""}},on),[_vm._v("mdi-calendar")])]}}],null,true)},[_c('span',[_vm._v("Wegen eines Schulanlasses abwesend.")])]):_vm._e(),_vm._l((item.justifications),function(justification){return _c('v-tooltip',{key:'justification' + justification.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g({attrs:{"attrs":attrs,"left":""}},on),[_vm._v(_vm._s(_vm.justificationCategoryIcon(justification.category)))])]}}],null,true)},[_vm._v("Absenz: "+_vm._s(justification.category.description)+" ")])}),_vm._l((item.dispensations),function(dispensation){return _c('v-tooltip',{key:'dispensation' + dispensation.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g({attrs:{"attrs":attrs,"left":""}},on),[_vm._v("mdi-cancel")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(dispensation.physicalEducation ? "Sportdispensation" : "Dispensation")),(dispensation.description)?_c('span',[_vm._v(": ")]):_vm._e(),_vm._v(" "+_vm._s(dispensation.description))])])}),_c('PersonName',{attrs:{"value":item.student,"flags":""}})],2)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }